body {
  font-family: 'Roboto';
  background-image: url('./assets/headerbgnav.png');
  background-repeat: no-repeat;
  object-fit: cover;
}

.nav-item a {
  font-family: 'Roboto', sans-serif;
  color: #2A3040B2 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

/* bottom card css */
.card_data {
  display: flex;
  width: 413px;

  height: 500px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 26px;
  position: relative;
  padding: 0px;
  cursor: pointer;
}

.card_data:hover {
  border: 1px solid #ccc;
  overflow: hidden;
}

.card-image,
.card-content {
  flex: 1;
  transition: transform 0.6s;
}

.card-image {
  position: relative;
  z-index: 1;
  width: 420px;
  overflow: hidden;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 20px;
  z-index: 2;
  transform: translateX(100%);
  position: absolute;
  overflow: hidden;
}

.card_data:hover .card-content {
  transform: translateX(0);
  overflow: hidden;
  /* width: 420px; */
}

.card_data:hover .card-image {
  transform: translateX(-100%);
  /* width: 413px;  */
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 10px 10px;
}

.card-hover-new {
  width: 350px;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin: 10px;
  cursor: pointer;
}

.card-images-1 {
  width: 340px;
  height: 330px;
  overflow: hidden;
}

.card-images-1 img {
  width: 340px;
  height: 330px;
  background-position: center;
  background-size: cover;
  transition: transform 0.3s ease;
}

.card-content-hover {
  padding: 16px 1px;
  text-align: center;

}

.card-hover-new:hover {
  transform: scale(1.05);
  width: 550px;
}

.card-hover-new:hover .card-images-1 img {
  width: 100%;
  margin-left: 0px;
  height: 470px;
}

.card-hover-new:hover .card-images-1 {
  float: left;
  /* Align image to the left */
  width: 53%;
  height: 470px;
  background-position: center;
  background-size: cover;
  margin-top: 20px;
}

.card-hover-new:hover .card-content-hover {
  float: right;
  /* Align content to the right */
  width: 47%;
  /* Adjust width */
  text-align: left;
  height: 562px;
  padding: 16px;
  /* Align text to the left */
}

.line-clamp {
  max-height: 200px;
  /* Adjust this value for more or fewer lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  /* Show 2 lines */
  transition: max-height 0.3s ease;
  /* Smooth transition */
}

/* On hover, remove line-clamp */
.card-hover-new:hover .line-clamp {
  max-height: none;
  /* Remove the height restriction */
  -webkit-line-clamp: unset;
  /* Remove the line clamp */
}

/* General setup */

.navbar-logo {
  width: 244px;
}

.card1 {
  width: 100%;
  margin-right: 25px;
  border-radius: 12px;
  overflow: hidden;
  height: 633px;
  position: relative;
  transition: all 0.4s ease;
}

.card--3 {
  height: 633px;
}

.card1:hover {
  color: white;
  background-color: white;
  /* box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1); */


}

.card__img {
  height: 355px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease-in-out;
}

.card--1 .card__img {
  background-image: url('./assets/small-size.png');
  height: 355px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease-in-out;
  position: absolute;
  bottom: 0;
  border-radius: 25px;
}

.card--2 .card__img {
  background-image: url('./assets/small-size3.png');
  width: 100%;
  height: 348px;
  border-radius: 20px;
}

.card--3 .card__img {
  background-image: url('./assets/small-img-size.png');
  width: 100%;
  height: 355px;
  border-radius: 30px;
  background-size: cover;
}

.card__content {
  padding: 16px;
  height: 265px;
  background-color: #FA9F3F33;
  border-radius: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all 0.4s ease;
}

.card__content1 {
  padding: 16px;
  height: 265px;
  background-color: #FA9F3F33;
  border-radius: 25px;
  position: relative;
  z-index: 2;
  top: 0;
  transition: all 0.4s ease;
}

.card__info {
  position: relative;
  z-index: 999;
}

.card__category {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: 'poppins';
}

.card__title {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: 'Roboto', ;
  line-height: 22px;
  font-weight: 300;
  color: #2A304080;

}

.card1:hover .card__title {
  color: white;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: 'Roboto', ;
  line-height: 22px;
  font-weight: 300;
}

.card-center-title {
  font-size: 20px;
  font-family: 'Poppins';
  line-height: 22px;
  font-weight: 400;

}

.card1:hover .card__content1 {
  padding: 16px;
  height: 265px;
  background-color: transparent;
  border-radius: 25px;
  position: relative;
  z-index: 2;
  top: 57%;
  transition: all 0.4s ease;
}

.card1:hover .card__content {
  background: rgba(0, 0, 0, 0);
  /* Remove background color */
  padding: 18px;
}

.card--1:hover .card__img {
  background-image: url('./assets/hover1.png');
  height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.card--2:hover .card__img {
  background-image: url('./assets/lard-size2.png');
  height: 600px;
  width: 500px;
  background-size: cover;
  background-repeat: no-repeat;
}

.card--3:hover .card__img {
  background-image: url('./assets/large-size.png');
  height: 600px;
  width: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 20px !important;
}

.card__img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease;
}


.card1:hover .card__img::after {
  opacity: 1;
}


.navbar-transparent {
  background-color: transparent;
  transition: background-color 0.4s ease;
}

.navbar-colored {
  background-color: white;
  transition: background-color 0.4s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.bg-bg {
  background-image: url('./assets/Group 1000004390.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 1013px;
  height: 478px;
  margin-top: 6.6% !important;
}

.bg-header-second {
  background-image: url('./assets/header-second.png');
  background-repeat: no-repeat;
  background-position: right;
  margin-top: -2%;
}

.contact-btn {
  background: linear-gradient(180deg, #FA9F3F 0%, #E9720E 100%);
  border-color: #f7941d;
  font-family: 'Roboto slab';
  font-weight: 400;
  border-radius: 16px;
  width: 131px;
  height: 41px;
  margin-left: 19px;
}

.top-banner {
  margin-top: 7%;
}

.hero-section-position {
  position: absolute;
  bottom: 30%;
  right: 60px;
  z-index: 2;
}

.card-rotate {
  background-color: #E5EBFC;
  transform: rotate(10deg);
  margin-bottom: 0px;
  margin-top: 30px;
  margin-right: 80px;
  margin-left: 6px;
  border-radius: 20px;
  border: 0px;
}

.card-rotate-1 {
  background-color: #FCE7D0;
  transform: rotate(10deg);
  margin-top: 80px;
  border-radius: 20px;
  border: 0px;
  margin-left: -24px;
}

.card-rotate-2 {
  background-color: #C5FFE6;
  transform: rotate(355deg);
  margin-right: 54px;
  margin-top: 150px;
  margin-bottom: 43px;
  border-radius: 20px;
  border: 0px;

}

.card-rotate-3 {
  background-color: #C3F1FE;
  transform: rotate(10deg);
  margin-right: 40px;
  margin-bottom: 120px;
  border-radius: 20px;
  border: 0px;
}

.card-width {
  height: auto;
  max-width: 271px;
}

.hero-section .text-orange {
  /* color: #E9720E; */
  font-family: 'Roboto', ;
  background: linear-gradient(180deg, #FA9F3F 0%, #E9720E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  font-weight: 800;
  text-underline-offset: 14px;

}

.hero-text {
  font-family: 'Roboto';
  font-size: 60px;
  font-weight: 800;
  margin-top: 27%;
}

.lead {
  margin-top: -3px !important;
  font-size: 16px !important;
  font-weight: 500;
  font-family: 'Roboto';
  line-height: 28px;
}

.div-lead .lead {
  font-size: 16px !important;
  font-family: 'Roboto';
  line-height: 28px;
  color: #2A3040;
  width: 90%;
  font-weight: 500;
  margin-top: -30px !important;
}

.div-lead .img {
  margin-left: 90px !important;
}

.btn-warning {
  border-radius: 16px;
  width: 143px;
  height: 43px;
  background: linear-gradient(180deg, #FA9F3F 0%, #E9720E 100%);
  border-color: #f7941d;
  font-family: 'Roboto slab';
  font-weight: 400;
  cursor: pointer;
  font-size: 18px;
  line-height: 26px;
}

.about-logo {
  width: 196px;
  height: 40px !important;
}

.floting-card-title {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 500 !important;

}

.floting-card-desc {
  font-size: 14px !important;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 18px !important;

}

.cenetr-card {
  margin-top: 22.5% !important;
  margin-left: 5%;

}

.star {
  width: 35px;
  height: 36px;
  background: linear-gradient(180deg, #FA9F3F 0%, #E9720E 100%);
  padding: 7px 0px;
}

.cenetr-card3 {
  margin-top: 7% !important;
  margin-left: 5% !important;
}

.cenetr-card4 {
  margin-left: 5%;
  margin-top: 10%;

}

.cenetr-card1 {
  margin-top: 21.5% !important;
  /* margin-left: 33%; */

}

.any-para {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  font-family: 'Roboto';
}


.hero-section .card-text {
  font-size: 14px;
  color: rgba(38, 43, 48, 0.5);
}

.banner-left,
.banner-right {
  max-height: 80px;
  width: auto;
  margin-top: -12%;
}

.card-bg-img {
  background-image: url('./assets/Group 1000004385.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -11.9% !important;


}

.boost_main {
  background-image: url('./assets/section2bg.png');
  background-size: contain;
  /* or contain, or specify dimensions */
  background-repeat: no-repeat;
  margin: 0px;

}

.join-logo2 {
  height: 25px !important;
  width: 142px;
  display: none;
}

.join-logo {
  height: 25px !important;
  width: 142px;
}

.add_hover_effect {
  border-radius: 35px;
  width: 37rem;
  background-color: white;
  transition: all 0.1s ease-in-out;
}

.add_hover_effect:hover {
  background-color: #2A3040;
}

.add_hover_effect:hover .card-title,
.add_hover_effect:hover .card-text,
.add_hover_effect:hover .card-text-orange,
.add_hover_effect:hover .card-title-orange,
.add_hover_effect:hover .join-logo2 {
  display: block;
  display: flex;
  color: white;
}

.add_hover_effect:hover .join-logo {
  display: none;
}

/* .hero-section .card-text:hover{
  color: white!important;
}
.wishlist:hover{
  color: white!important;
} */
.img-fluid {
  max-width: 100%;
  height: auto;
}

.card-img {
  height: 137px;
  width: 206px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 28px; */
}

.card-title {
  font-size: 20px !important;
  font-family: 'Poppins';
  font-weight: 500;
}

.card-text {
  font-size: 14px !important;
  font-weight: 400;
  font-family: 'Roboto';
}

.bg-img-bridge {
  background-image: url('./assets/new-imgadd.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 498px;
  width: 100%;
  margin-top: -11%;

}

.setu-first-l {
  font-size: 40px;
  font-family: 'Roboto';
  font-weight: 600;
  line-height: 60px;
}

.bridge-img-desc {
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 400;
}

.setu {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Roboto';
}

.bridge-card {
  width: 250px !important;
  height: 242px;
}

.bridge-card-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  font-family: 'Roboto' !important;
}

.bridge-card-desc {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto' !important;
  line-height: 22px !important;
  color: 'rgba(42, 48, 64, 0.88)';
  margin-left: 40px;
}

.contact-text {
  font-weight: 600;
  font-size: 48px;
  font-family: 'Oswald';
}

.contact-text-orange {
  color: #E9720E;
  font-weight: 600;
  font-size: 48px;
  font-family: 'Oswald';

}

.wishlist {
  color: #2A3040;
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 500;
}

.bg-line {
  width: 21rem;
  background-repeat: no-repeat;
  margin-left: 29px;

}

.skills h1 {
  color: #FA9F3F;
  font-size: 60px;
  font-family: 'Oswald';
  font-weight: 500;
}

.skills .boost {
  font-size: 100px;
  font-family: 'Oswald';
  font-weight: 700;
  line-height: 70px;
  color: rgba(42, 48, 64, 1) !important;
}

.skills p {
  font-size: 16px;
  color: rgba(32, 32, 32, 0.5);
  line-height: 24px;
  word-spacing: 2px;

}

.exprence {
  width: 100%;
}

.exprence .circle {
  width: 60px;
  height: 60px;
  background: #9fa1a8a3;
}

.add_font_revenue {
  font-size: 32px;
  line-height: 0px;
  font-family: 'Oswald';
  font-weight: 400;
}

.exprence p {
  font-size: 16px;
  font-family: 'Roboto';
  color: rgba(42, 48, 64, 1);
  font-weight: 500;

}

.frame {
  background-image: url('./assets/Frame\ 1.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 206px;
  background-size: cover;
  width: 100%;
}

.arrow {
  height: 33px !important;
  width: 49px;
  border-radius: 12px;
  background: linear-gradient(180deg, #FA9F3F 0%, #E9720E 100%);
}

.arrow i {
  font-size: 10px;
}

.step-btn {
  width: 316px;
  height: 60px;
  background-color: #FFFFFF;
  position: relative;
  border: 1px solid transparent;
  /* Default border */
  transition: all 0.8s ease-in-out;
  border-radius: 10px;
  color: #2A3040 !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 600 !important;
}

.step-bg-img {
  background-image: url('./assets/Vector\ 18.png');
  background-repeat: no-repeat;
}

.step-btn:hover {
  border: 1px solid #FA9F3F;
}

.step-btn.active {
  border: 1px solid #FA9F3F;
}

.step-btn.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  /* Position below the button */
  left: 50%;
  width: 0;
  /* Start with 0 width */
  height: 3px;
  background-color: #FA9F3F;
  transition: width 0.4s ease-in-out;
  transform: translateX(-50%);
}

.step-btn.active::after {
  width: 50%;
  /* Final width when active */
}

.keybanefits-step {
  margin-top: 1px;
}

.apply-btn {
  width: 130px;
  height: 50px;
  border-radius: 20px;
  border: 1px solid #FA9F3F;
  overflow: hidden;
  /* Ensures no content overflow when width changes */
  transition: width 0.3s ease;
  /* Smooth transition for width */
}

.apply-btn:hover {
  width: 177px;
}

.apply-btn button {
  width: 139px;
  height: 50px;
  font-weight: 700 !important;
  font-size: 16px;
  font-family: 'Roboto', sans-serif !important;
  background: linear-gradient(270deg, #FA9F3F 0%, #E9720E 100%);
  border-radius: 20px;
  position: relative;
}

.apply-btn i {
  font-size: 16px;
  color: #f7941d;
}

.account-img {
  width: 563px;
  height: 571px;
  object-fit: contain;
}

.account-img-4 {
  width: 363px;
  height: 271px;
  object-fit: cover;
  margin-bottom: 79px;
}

.setu-first-l {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-icon {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-btn-2 {
  width: 170px;
  height: 40px;
  font-size: 14px;
  border-radius: 10px;
  background-color: white;
  font-family: 'Roboto', sans-serif !important;
}

.bg-frame {
  background-image: url('./assets/Rectangle 3468020.png');
  height: 291px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100% !important;
  background-size: cover;
}

.brand-logo {
  width: 70px;
}

.start {
  width: 35px;
  height: 36px;
  font-size: 30px;
  background-color: #f7941d;
}

/* Position quote symbol outside of card */
.quote-symbol-container {
  display: flex;
  gap: 5px;
  transform: translate(10px, 19px) !important;
  z-index: 999 !important;

}

.quote-symbol {
  width: 26px;
  height: 49px;
  color: #FFA500;
  line-height: 1;
}



/* Custom Navigation Buttons */
.custom-nav-btn {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.custom-nav-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.see-all-btn {
  border-radius: 12px;
  border: 1px solid #FFA500;
  color: #f7941d;

}

.see-all-btn:hover {
  color: #f7941d;
  border: 1px solid #FFA500;
}

.card-heading {
  font-style: 20px;
}

.insight-img {
  width: 342px;
}

.insight-title {
  font-size: 24px;
}

.footer {
  background-image: url('./assets/Group\ 1000004386.png');
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0;
}

.footer-logo-para {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
  color: rgba(217, 217, 217, 1) !important;
}

.footer-link-head {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Roboto';
}

.footer-email {
  font-size: 14px;
  font-weight: 400 !important;
  font-family: 'Roboto';
  color: rgba(217, 217, 217, 1) !important;

}

.footer-logo {
  margin-left: -120px !important;
  max-width: 100%;
}

.input {
  height: 45px !important;
  border-radius: 10px 0 0 10px !important;
}

.input::placeholder {
  font-size: 14px;
  color: #C9C9C9;
}

.input-btn {
  background: linear-gradient(180deg, #FA9F3F 0%, #E9720E 100%);
  border-color: #f7941d;
  font-family: 'Roboto slab';
  font-weight: 600;
  border-radius: 0 10px 10px 0;
  height: 45px;
  position: relative;
  width: 123px;
}

.card-rating {
  font-size: 12px;
  font-family: 'poppins';
  font-weight: 400;
}

.card-user-img {
  width: 78px;
  height: 68px;
  object-fit: cover;
}

.insight-desc {
  font-size: 16px;
  font-family: 'roboto';
  font-weight: 400;
  line-height: 24px;
}

.card-user-name {
  font-size: 14px;
  font-family: 'poppins';
  font-weight: 500;
  margin: 0px !important;
}

.card-heading {
  font-style: 20px;
  font-family: 'poppins';
  font-weight: 500;

}

.slide-card {
  border: 1px solid #2A30400D !important;
  transition: all 1s ease-in-out;
  height: 324px;
  width: 589px;
}

.slide-card:hover {
  border: none;
  box-shadow: 0 6px 16px 4px rgba(0, 0, 0, 0.15);
  height: 324px;
  width: 589px;
}

.step-heading {
  font-size: 36px;
  font-weight: 700;
  font-family: 'roboto slab';
  color: #2A3040 !important;
}

.step-para {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto' sans-serif !important;
  color: #2A304080;

}

.key-banefits {
  font-size: 16px;
  font-family: "Roboto" sans-serif;
  font-weight: 400;
}

.see-all-btn {
  width: 120px;
  height: 36px;
  border-radius: 12px;
  border: 1px solid #FFA500;
  color: #f7941d;

}

.add_logo_size {
  width: 12rem;
  height: 2rem;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

input,
textarea {
  font-size: 1rem;
}

button {
  font-size: 1.2rem;
}

.text-probill-rcm {
  font-size: 48px;
  font-family: 'Roboto';
  font-weight: 600;
  line-height: 54px;

}


.text-probill-para {
  font-size: 20px;
  /* font-family: 'roboto'; */
  font-weight: 400;
}

.text-probill-rcm-orange {
  font-size: 48px;
  font-family: 'Roboto';
  font-weight: 600;
  line-height: 40px;
  color: #E9720E;
  border-bottom: 4px solid #E9720E;
  /* Add color for clarity */
  padding-bottom: 8px;
  /* Adjust this value to control the spacing */
  text-decoration: none;
  /* Ensure no default underline */
  margin-top: 13px;
}

.add_width_height_img {
  width: 100%;
  height: 17rem;
}

.card_data {
  display: flex;
  /* width: 329px; */
  height: 500px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 26px;
  position: relative;
  padding: 0px;
  cursor: pointer;
}

.card-image,
.card-content {
  flex: 1;
  /* Equal width for both sections */
  transition: transform 0.6s;
  /* backface-visibility: hidden; */
}

.card-image {
  position: relative;
  z-index: 1;
}

.card-content {
  display: flex;
  align-items: center;
  /* Center content vertically */
  justify-content: center;
  /* Center content horizontally */
  background: #fff;
  padding: 20px;
  z-index: 2;
  transform: translateX(100%);
  /* Start hidden */
  position: absolute;
}

.card_data:hover .card-content {
  transform: translateX(0);
  /* Slide in on hover */
}

.card_data:hover .card-image {
  transform: translateX(-100%);
  /* Slide out on hover */
}

.card-image img {
  width: 100%;
  height: 100%;
  border-radius: 8px 0 0 8px;
  /* Rounded corners on the left */
}

/* Hover Effect */
.card1:hover .card__img {
  height: 655px;
  border-radius: 20px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.modal-heading h1 {
  font-size: 48px;
  font-weight: 600;
}

.contact-input {
  height: 43px;
}

.gradient-box {
  background: linear-gradient(180deg, #FA9F3F 0%, #E97424 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.contact-input::placeholder {
  color: #2A304080;
  font-size: 16px;
}

/* accordion css */
.service-header {
  font-size: 36px;
  font-weight: 600;
  font-family: "Roboto Slab";
}

.accordion-img {
  height: 520px;
  width: 100% !important;
}

.accordion-width {
  width: 550px;
}

.accordion-header {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto';
  margin-top: 0px;
}

.accordion-body {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto';
  line-height: 28px;
}

/* last section */
.about-left-img {
  height: 700px;
}

.about-head {
  font-weight: 800;
  font-size: 24px;
  font-family: 'Roboto';
}

.about-head2 {
  font-weight: 900;
  font-size: 40px;
  font-family: "Mulish", sans-serif;
  line-height: 48px;

}

.about-logo-bottom {
  height: 25px;
  margin-bottom: 10px;
}

.about-first-head {
  font-size: 24px;
  font-weight: 900;
  font-family: 'Mulish';
}

.about-first-desc {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto';
}

.number-bg-img {
  background-image: url('./assets/about-angle.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 134px;
  height: 64px;
  font-weight: 900;
  font-size: 30px;
  font-family: 'Roboto';
}

.one {
  margin-top: -13px;
  font-family: 'Mulish';
}

.number-bg-img1 {
  background-image: url('./assets/about-angle.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 134px;
  height: 64px;
  font-weight: 900;
  font-size: 30px;
  font-family: 'Roboto';
}

.step-bg-img {
  background-image: url('./assets/accordion-bgimg.png');
  background-size: auto;
  padding: 0px;
  background-repeat: no-repeat;
}

.about-bg-img {
  background-image: url('./assets/about-bgimg1.png');
  background-size: auto;
  padding: 0px;
  background-position: left top;
  background-repeat: no-repeat;

}

.about-bg-img2 {
  background-image: url('./assets/about-bgimg2.png');
  background-size: auto;
  padding: 0px;
  background-position: right bottom;
  background-repeat: no-repeat;

}

.number-bg-img2 {
  background-image: url('./assets/about-angle.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 240px;
  height: 64px;
  font-weight: 900;
  font-size: 30px;
  font-family: 'Roboto';
}

.media-icon {
  width: 160px;
}

.media-icon .footer-icon {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.footer-bottom-link {
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

@media (max-width: 992px) {
  .diagonal-image {
    clip-path: none;
  }
}

@media (max-width: 1920px) {
  .card-rotate {
    margin-top: 65px;
  }

  .card-bg-img {
    margin-top: -11.9% !important;
  }

  .footer-logo {
    width: 300px;
    margin-left: 10px;

  }
}
@media (min-width:1000px) and (max-width:1399px){
  .skills .boost {
    margin-left: -47px ;
  }
.card-pillar1{
  margin-left: -75px;
}
.card-pillar2{
  margin-left: -29px;
}
.card-pillar3{
  margin-left: 23px;
}
.card-pillar4{
  margin-left: 75px;
}
.accordion-img{
  margin-top: 235px !important;
  width: 100% !important;
  height: 413px;

}
.bg-img-bridge{
  width: 90%;
  background-size: cover;
  background-position: center;
}
}

@media (min-width : 1200px) and (max-width: 1800px) {
  .card-rotate {
    margin-bottom: 47px;
    margin-right: 150px;
    margin-top: 40px;
    margin-left: 0px;
  }

  .card-rotate-1 {
    margin-top: 20px;
    border-radius: 20px;
  }

  .card-rotate-2 {
    margin-right: -126px;
    margin-top: 107px;
    border-radius: 20px;

  }

  .card-rotate-3 {
    margin-right: 20px;
    margin-bottom: 125px;
    border: 0px;
  }

  .card-width {
    height: 247px;
    max-width: 243px;
    margin-top: 120px;
  }

  .card-img {
    height: 77px;
    width: 119px;
  }

  .card-bg-img {
    margin-top: -237px !important;


  }

  .lead {

    margin-top: -30px;
  }
  

}

@media only screen and (max-width: 768px) and (min-width: 360px) {
  .step-bg-img {
    background-image: none;
  }

  .lead {
    margin-top: -10px !important;
    font-size: 10px !important;
    font-weight: 400;
    font-family: 'Roboto';
    line-height: 14px;
  }

  .about-bg-img {
    background-image: none;
  }

  .about-bg-img2 {
    background-image: none;
  }

  /* last section */
  .about-left-img {
    height: 400px;
    width: 100%;
  }

  .accordion-img {
    height: 301px;
    width: 100%;
  }

  .accordion-width {
    width: 100%;
  }

  .about-head {
    font-size: 16px;
  }

  .about-head2 {
    font-size: 18px;
  }

  .about-logo-bottom {
    height: 15px;
    margin-bottom: 6px;
  }

  .about-first-head {
    font-size: 16px;
  }

  .about-first-desc {
    font-size: 12px;
  }

  .number-bg-img {
    background-image: none;
    font-size: 16px;
    font-family: 'Roboto';
  }

  .number-bg-img1 {
    background-image: none;
    font-size: 16px;
  }

  .number-bg-img2 {
    background-image: none;
    font-size: 16px;
  }

  /* accordion css */
  .service-header {
    font-size: 20px;
  }

  .accordion-header {
    font-size: 13px;
  }

  .accordion-body {
    font-size: 11px;
  }

  .add_logo_size {
    width: 8rem;
    height: 1.5rem;
  }

  .modal-heading h1 {
    font-size: 24px;
    font-weight: 600;
  }

  .contact-input {
    height: 30px;
  }

  .contact-input::placeholder {
    color: #2A304080;
    font-size: 12px;
    padding: 1px 5px;
  }

  .bg-img-bridge {
    background-image: url('./assets/new-imgadd.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 167px;
    width: 100%;

  }

  .contact-text {
    font-size: 23px;
  }

  .contact-text-orange {
    font-size: 23px;

  }

  .setu-first-l {
    font-size: 40px !important;
    margin-top: 17px;
  }

  .bridge-img-desc {
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 400;
  }

  .setu {
    font-size: 13px;
    font-weight: 600;
    font-family: 'Roboto';
    margin: 0;
  }

  .bridge-card {
    margin: -20px;
    height: 200px;
    margin-bottom: 10px;
  }

  .bridge-card-title {
    font-size: 16px;

  }

  .bridge-card-desc {
    font-size: 12px;
    margin-left: 0px;
    text-align: center !important;


  }

  .apply-btn {
    width: 80px;
    height: 30px;
    margin-bottom: 10px;

  }

  .apply-btn button {
    width: 80px;
    height: 30px;
    font-size: 10px;
  }

  .quote-symbol {
    width: 18px;
    height: 35px;
    color: #FFA500;
    line-height: 1;
  }

  .footer-email {
    font-size: 12px;
  }

  .media-icon {
    width: 100%;
  }

  .media-icon .footer-icon {
    width: 20px;
    height: 20px;
    margin: 5px;
    margin-top: 10px !important;
  }

  .about-logo {
    width: 100px;
    height: 15px !important;
    margin-top: 10px !important;
    margin-left: 10px;
  }

  .bg-frame {
    height: 200px;
  }

  .input {
    height: 35px !important;
  }

  .contact-btn {
    width: 129px !important;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 0px !important;
  }

  .input-btn {
    width: 90px;
    height: 35px;
  }

  .hero-text {
    font-size: 33px;
  }

  .lead {
    font-size: 13px;
    width: 78% !important;
    margin-top: -20px;
  }

  .card-bg-img {
    background-image: none !important;
    height: 170px;
    margin-top: 3% !important;
  }

  .card-img {
    width: 127px;
  }

  .floting-card-desc {
    font-size: 6px !important;
    line-height: 8px !important;
    margin-top: 5px;
  }

  .skills h1 {
    text-align: end;
  }

  .add_font_revenue {
    font-size: 25px;
    margin-top: 28px;
    text-align: end;
  }

  .skills p {
    font-size: 23px;
    line-height: 29px;
    text-align: end;
  }

  .text-probill-rcm-orange {
    font-size: 36px !important;
  }

  .text-probill-para {
    font-size: 20px !important;
  }

  .step-heading {
    font-size: 23px;

  }

  .step-para {
    font-size: 15px;

  }

  .add_width_height_img {
    width: 95%;
    height: 17rem;
  }

  .key-banefits {
    font-size: 10px;
    margin: 0px;
  }

  .slide-card {
    border: 1px solid #2A30400D !important;
    transition: all 1s ease-in-out;
    height: 324px;
    width: 589px;
  }

  .see-all-btn {
    font-size: 15px;
    width: 104px;

  }

  .contact-btn-2 {
    margin-top: -6%;
  }

  .footer {
    height: 692px;
    background-size: cover;
  }

  .footer-logo {
    width: 239px;
  }

  .footer-email {
    font-size: 12px;
    margin: 0px;
  }

  .footer-logo-para {
    font-size: 14px;

  }

  .footer-link-head {
    font-size: 13px;

  }

  .input {
    margin-top: 20px;
    height: 35px;
  }

  .navbar-logo {
    width: 120px;
  }

  .input {
    height: 35px !important;
  }



  .input-btn {
    width: 80px;
    height: 35px !important;
    margin-left: 0;
    margin-top: 20px !important;

  }


  .card-rotate-1 {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .card-rotate-2 {
    margin-right: 18px;
    margin-top: 0px;
    margin-bottom: 0px;

  }

  .floting-card-title {
    font-size: 8px;
    margin: 1px !important;
    line-height: 10px;
  }

  .card-img {
    width: 70px;
  }


  .text-probill-rcm-orange {
    font-size: 20px !important;
  }

  .card__category {
    font-size: 20px;
    line-height: 20px;

  }

  .card-center-title {
    font-size: 15px;
    line-height: 15px !important;
  }


  .frame {
    height: 301px !important;
    margin-top: -87px;
  }

  .any-para {
    font-size: 14px;
    line-height: 18px;
  }

  .contact-btn-2 {
    font-size: 9px !important;
  }


  .slide-card {

    height: 324px;
    width: 360px !important;
  }

  .card-heading {
    font-style: 15px;
  }

  .insight-img {
    width: 320px;
  }

  .insight-title {
    font-size: 18px;
  }

  .card-rating {
    font-size: 9px;
    font-family: 'poppins';
    font-weight: 400;
  }

  .card-user-img {
    width: 40px;
    height: 35px;
    object-fit: cover;
  }

  .bg-frame {
    height: 139px;
  }

  .start {
    width: 20px;
    height: 19px;
    font-size: 14px;
    background-color: #f7941d;
  }

  .see-all-btn {
    font-size: 10px;
    width: 75px;
  }

  .footer-logo {
    margin-left: -29px;
    max-width: 77%;
  }

  .footer {
    height: 818px !important;
    width: 100%;
    background-size: cover;
  }

  .keybanefits-step {
    margin-top: -85px;
  }

  .card1 {
    width: 100%;
    margin-right: 25px;
    border-radius: 12px;
    overflow: hidden;
    height: 515px;
    position: relative;
    transition: all 0.4s ease;
  }

  .card1:hover {
    color: white;
    height: 432px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 0px -0px rgba(0, 0, 0, 0.1);



  }

  .card--3 {
    height: 530px !important;
    margin-bottom: 43px !important;
    margin-top: -32px !important;
  }

  .card--3:hover {
    height: 400px !important;
    margin-bottom: 87px !important;
  }

  .card--2:hover {
    margin-bottom: 62px;
  }

  .card__img {
    height: 355px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.4s ease-in-out;
  }

  .card--1 .card__img {
    background-image: url('./assets/hover1.png');
    height: 270px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.4s ease-in-out;
    position: absolute;
    bottom: 62px;
    border-radius: 25px;
  }

  .card--2 .card__img {
    background-image: url('./assets/hover2.png');
    width: 100%;
    height: 275px;
    border-radius: 20px;
  }

  .card--3 .card__img {
    background-image: url('./assets/hover3.png');
    width: 100%;
    height: 270px;
    border-radius: 30px;
    background-size: cover;
  }

  .card__content {
    padding: 16px;
    height: 195px;
    background-color: #FA9F3F33;
    border-radius: 25px;
    position: absolute;
    bottom: 59px;
    width: 100%;
    transition: all 0.4s ease;
  }


  .card__info {
    position: relative;
    z-index: 999;
  }

  .card__category {
    font-size: 15px;
    line-height: 40px;
    font-weight: 500;
    font-family: 'poppins';
  }



  .card-center-title {
    font-size: 13px !important;
    font-family: 'Poppins';
    line-height: 13px;
    font-weight: 400;

  }


  .card__content1 {
    padding: 16px;
    height: 205px;
    background-color: #FA9F3F33;
    border-radius: 25px;
    position: relative;
    z-index: 2;
    top: 39px;
    transition: all 0.4s ease;
  }

  .card__title {
    margin-top: -6px;
    margin-bottom: 10px;
    font-size: 12px !important;
    font-family: 'Roboto', ;
    line-height: 15px;
    font-weight: 300;
    color: #2A304080;
  }

  .card1:hover .card__title {
    color: white;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: 'Roboto', ;
    line-height: 20px;
    font-weight: 300;
  }

  .card__img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s ease;
  }



  .card1:hover .card__img::after {
    opacity: 1;
  }

  .footer-email {
    font-size: 14px;
  }


  .footer {
    padding: 15px;
    height: 1017px;
    background-size: cover;
  }

  .navbar-logo {
    width: 120px;
  }

  .input {
    height: 35px !important;
  }

  .input-btn {
    width: 80px;
    height: 35px !important;
    margin-left: 0;
    margin-top: 0px;

  }


  .card-img {
    width: 70px;
  }


  .text-probill-rcm-orange {
    font-size: 20px !important;
  }

  .card__category {
    font-size: 20px;
    line-height: 20px;

  }

  .card-center-title {
    font-size: 15px;
  }


  .frame {
    height: 299px !important;
    margin-top: -87px;
  }

  .any-para {
    font-size: 14px;
    line-height: 18px;
  }

  .contact-btn-2 {
    font-size: 9px !important;
    margin-left: 18px;
  }


  .slide-card {

    height: 324px;
    width: 360px !important;
  }

  .card-heading {
    font-style: 15px;
  }

  .insight-img {
    width: 320px;
  }

  .insight-title {
    font-size: 18px;
  }

  .card-rating {
    font-size: 9px;
    font-family: 'poppins';
    font-weight: 400;
  }

  .card-user-img {
    width: 40px;
    height: 35px;
    object-fit: cover;
  }

  .bg-frame {
    height: 139px;
  }

  .start {
    width: 20px;
    height: 19px;
    font-size: 14px;
    background-color: #f7941d;
  }

  .see-all-btn {
    font-size: 10px;
    width: 75px;
  }


  .keybanefits-step {
    margin-top: -85px;
  }

  .card1 {
    width: 100%;
    margin-right: 25px;
    border-radius: 12px;
    overflow: hidden;
    height: 534px;
    position: relative;
    transition: all 0.4s ease;
    margin-top: -12px;
  }

  .card__img {
    height: 355px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.4s ease-in-out;
  }

  .card--1 .card__img {
    background-image: url('./assets/image (11).png');
    height: 270px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.4s ease-in-out;
    position: absolute;
    bottom: 62px;
    border-radius: 25px;
  }

  .card--2 .card__img {
    background-image: url('./assets/image (12).png');
    width: 100%;
    height: 275px;
    border-radius: 20px;
  }

  .card--3 .card__img {
    background-image: url('./assets/image (13).png');
    width: 100%;
    height: 270px;
    border-radius: 30px;
    background-size: cover;
  }

  .card__content {
    padding: 5px 13px;
    height: 195px;
    background-color: #FA9F3F33;
    border-radius: 25px;
    position: absolute;
    bottom: 59px;
    width: 100%;
    transition: all 0.4s ease;
  }


  .card__info {
    position: relative;
    z-index: 999;
  }

  .card__category {
    font-size: 15px;
    line-height: 40px;
    font-weight: 500;
    font-family: 'poppins';
  }



  .card-center-title {
    font-size: 13px !important;
    font-family: 'Poppins';
    line-height: 13px;
    font-weight: 400;

  }

  .card1:hover .card__content1 {
    padding: 16px;
    height: 265px;
    background-color: transparent;
    border-radius: 25px;
    position: relative;
    z-index: 2;
    top: 45%;
    transition: all 0.4s ease;
  }

  .card1:hover .card__content {
    background: rgba(0, 0, 0, 0);
    /* Remove background color */
  }

  .card__img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s ease;
  }


  .card1:hover .card__img::after {
    opacity: 1;
  }

  .exprence p {
    font-size: 8px;
    font-family: 'Poppins';
    line-height: 14px;
  }

  .about-logo {
    width: 111px;
    height: 20px !important;
    margin-left: 15px;
    margin-top: 5px !important;
  }

  .footer {
    padding: 15px;
    height: 795px !important;
    background-size: cover;
  }

  .hero-section h1 {
    font-family: 'Oswald', ;
    font-size: 16px;
  }

  .cenetr-card {
    margin-top: 0% !important;
    margin-left: 0%;
    transform: rotate(360deg);

  }

  .cenetr-card3 {
    margin-top: 0% !important;
    margin-left: 0% !important;
  }

  .cenetr-card4 {
    margin-left: 5%;
    margin-top: 0%;

  }

  .cenetr-card1 {
    margin-top: 0% !important;
    margin-left: 0%;

  }

  .footer-email {
    font-size: 11px;
  }


  .input {
    height: 30px !important;
  }

  .input-btn {
    width: 75px;
  }



  .bg-header-second {
    background-image: none;

  }


  .input {
    height: 35px !important;
  }

  .input-btn {
    width: 90px;
  }

  .hero-text {
    font-size: 18px;
  }

  .text-orange {
    font-size: 16px !important;
    margin: 0px;
  }

  .lead {
    font-size: 6px;
    width: 100% !important;
    margin-top: -14px;
  }

  .hero-section-position {
    bottom: 30%;
    right: 60px;
    z-index: 2;
  }

  .card-img {
    width: 64px;
  }

  .about-logo {
    width: 111px;
    height: 20px;
    margin-left: 15px;
  }

  .footer-email {
    font-size: 12px;
  }

  .bg-header-second {
    background-image: none;

  }

  body {
    font-family: 'Roboto';
    background-image: none;

  }

  .boost_main {
    background-image: none;
  }




  .about-logo {
    width: 100px;
    height: 20px !important;
    margin-left: 10px;
    margin-top: 10px !important;
  }

  .bg-bg {
    width: 342px;
    height: 159px;
    margin-top: 23% !important;

  }

  .bg-frame {
    height: 170px;
  }

  .hero-text {
    font-size: 18px;
  }

  .lead {
    font-size: 7px;
    width: 100% !important;
    margin-top: -20px;
  }

  /* button */
  .btn-warning {
    width: 76px;
    font-size: 10px;
    height: 26px;
    padding: 0px;
    margin-top: -3% !important;
  }

  .card-rotate {
    margin-bottom: 0px !important;
    margin-left: 20px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }


  .card-rotate-3 {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 0% !important;
  }


  .card-width {
    height: 148px;
    max-width: 164px;
    transform: rotate(360deg);

  }

  .card-img {
    width: 70px;
  }

  .add_hover_effect {
    width: 113%;
    margin-top: -14px;
    margin-left: 3px !important;
  }

  .skills h1 {
    text-align: end;
    font-size: 35px;
  }

  .add_font_revenue {
    font-size: 25px;
    margin-top: 28px;
    text-align: end;
  }

  .skills .boost {
    font-size: 50px;
    margin-left: 47px ;
  }

  .skills p {
    font-size: 13px;
    line-height: 15px;
    text-align: end;
  }

  .text-probill-rcm {
    font-size: 21px !important;
    line-height: 31px !important;
    text-align: center;
    margin-top: -18px;

  }

  .text-probill-rcm-orange {
    font-size: 21px !important;
    border-bottom: none;
    /* Add color for clarity */
    padding-bottom: 1px;
    /* Adjust this value to control the spacing */
    text-decoration: none;
    /* Ensure no default underline */
    margin-top: 1px;
  }

  .text-probill-para {
    font-size: 15px !important;
    text-align: center;
  }

  .step-btn {
    width: 100px;
    height: 30px;
    font-size: 5px;
    padding: 4px;
  }

  .step-heading {
    font-size: 20px;

  }

  .step-para {
    font-size: 10px;

  }


  .slide-card {
    border: 1px solid #2A30400D !important;
    transition: all 1s ease-in-out;
    height: 400px !important;
    width: 100%;
  }

  .see-all-btn {
    font-size: 15px;
    width: 104px;

  }

  .frame {
    height: 250px !important;
    margin-top: -132px;

  }

  .navbar-logo {
    width: 147px;
  }

  .footer {
    height: auto !important;
    width: 100%;
    background-size: cover !important;
    ;
  }

  .account-img {
    margin-top: -19%;
    width: 100%;
    height: 518px;
    background-size: cover !important;
  }

  .card_data {
    border: none;
    height: 410px !important;
    width: 330px;
  }

  .insight-desc {
    font-size: 12px;
    font-family: 'roboto';
    font-weight: 400;
    line-height: 20px;
  }

  .card-content {
    width: 100%;
  }

  .card-image img {
    width: 100%;
    height: 100%;
    border-radius: 8px 0 0 8px;
  }

  .keybanefits-step {
    margin-top: -85px;
  }



  .card__img {
    height: 355px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.4s ease-in-out;
  }

  .card--1 .card__img {
    background-image: url('./assets/image (11).png');
    height: 270px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.4s ease-in-out;
    position: absolute;
    bottom: 18px;
    border-radius: 25px;

  }

  .card--2 .card__img {
    background-image: url('./assets/image (12).png');
    width: 100%;
    height: 275px;
    border-radius: 20px;

  }

  .card--3 .card__img {
    background-image: url('./assets/image (13).png');
    width: 100%;
    height: 270px;
    border-radius: 30px;
    background-size: cover;


  }

  .card__content {
    padding: 16px;
    height: 195px;
    background-color: #FA9F3F33;
    border-radius: 25px;
    position: absolute;
    bottom: 60px;
    width: 100%;
    transition: all 0.4s ease;
  }


  .card__info {
    position: relative;
    z-index: 999;
  }

  .card__category {
    font-size: 15px;
    line-height: 40px;
    font-weight: 500;
    font-family: 'poppins';
  }


  .card-center-title {
    font-size: 13px !important;
    font-family: 'Poppins';
    line-height: 13px;
    font-weight: 400;

  }

  /* Hover Effect */
  .card1:hover .card__img {
    height: 431px;
    border-radius: 20px;
    background-size: cover;
  }

  .card1:hover .card--3 .card__img {
    margin-top: -30px;

  }


  .card__img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s ease;
  }


  .card1:hover .card__img::after {
    opacity: 1;
  }

}